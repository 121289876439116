export function uniqueBy<T>(arr: T[], key: keyof T): T[] {
    const result: T[] = [];
    for (const entry of arr) {
        const existingEntry = result.find(e => e[key] === entry[key]);
        if (!existingEntry) {
            result.push(entry);
        }
    }

    return result;
}
