import {api} from '@/shared/api';
import {Day, Timesheet, TimesheetsBasicViewModelsPaged, TimesheetStatus} from '@/timesheets/store/Timesheet';
import {ID} from '@/shared/store/AbstractEntity';
import {Project} from '@/dictionaries/projects/store';

import {converter as leaveConverter} from '@/attendance/store/converter';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';
import {LeaveRequestStatus} from '@/attendance/store/LeaveRequestStatus';
import {formatDate} from '@/shared/date/formatDate';

export async function getProjects(employeeId: ID): Promise<Project[]> {
    const {data} = await api<Project[]>({
        url: `Projects/employee/${employeeId}`,
    });

    return data;
}

export async function fetchTimesheetsPage(employeeId: ID, page: number, itemsPerPage: number): Promise<TimesheetsBasicViewModelsPaged> {
    const {data} = await api<TimesheetsBasicViewModelsPaged>({
        method: 'get',
        url: `Timesheets/employee/${employeeId}?page=${page}&itemsPerPage=${itemsPerPage}`,
    });

    return data;
}

export async function fetchTimesheetsByDate(employeeId: ID, year: number, month: number): Promise<Timesheet[]> {
    const {data} = await api<Timesheet[]>({
        method: 'get',
        url: `Timesheets/employee/${employeeId}/${year}/${month}`,
    });

    return data;
}

export async function getTimesheet(id: string | ID): Promise<Timesheet> {
    const {data} = await api<Timesheet>({
        method: 'get',
        url: `Timesheets/${id}`,
    });

    const timesheet = data;
    let leaveRequest: LeaveRequest<string>;

    for (leaveRequest of timesheet.leaveRequests) {
        if (leaveRequest.leaveRequestStatus === LeaveRequestStatus.Declined) {
            continue;
        }
        const hydrated = leaveConverter.hydrate(leaveRequest) as LeaveRequest<Date>;
        for (const dayRecord of timesheet.days) {
            if (!dayRecord.leaveRequests){
                dayRecord.leaveRequests = [];
            }
            const dayDate = new Date(timesheet.year, timesheet.month - 1, dayRecord.day);
            if (dayDate >= hydrated.startDate && dayDate <= hydrated.endDate) {
                dayRecord.leaveRequests.push(hydrated);
            }
        }
    }
    return timesheet;
}

export async function getTimesheetForDate(employeeId: ID, date: Date = new Date()): Promise<Timesheet> {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const timesheets = await fetchTimesheetsByDate(employeeId, year, month);

    const timesheetMeta = timesheets.find((ts) => {
        return ts.year === year && ts.month === month;
    });

    if (timesheetMeta) {
        return await getTimesheet(timesheetMeta.id);
    } else {
        throw new Error(`No timesheet for ${formatDate(date)}`);
    }
}

export async function saveTimesheet(timesheet: Timesheet): Promise<Timesheet> {
    const {id, year, month, employeeId} = timesheet;

    const days: Day[] = timesheet.days.reduce<Day[]>((newDays, day) => {
        const activities = day.activities.filter((activity) => activity.hours > 0 || activity.note || activity.overtime);
        if (activities.length) {
            newDays.push({
                ...day,
                activities,
            });
        }

        return newDays;
    }, []);

    const {data} = await api<Timesheet>({
        method: 'put',
        url: `Timesheets/${id}`,
        data: {
            id,
            year,
            month,
            employeeId,
            days,
        },
    });

    return data;
}

export async function submitTimesheet(timesheet: Timesheet): Promise<Timesheet> {
    const {data} = await api<Timesheet>({
        method: 'post',
        url: `Timesheets/submit/${timesheet.id}`,
    });

    return data;
}

export async function getReviewTimesheets(timesheetStatus: TimesheetStatus): Promise<Timesheet[]> {
    let endpoint = '';
    const listName = 'statuses';

    if(timesheetStatus == TimesheetStatus.ReadyToApproveOrDeclined){
        endpoint = `${listName}=${TimesheetStatus.ReadyToApprove.toString()}&${listName}=${TimesheetStatus.Declined.toString()}`;
    }else{
        endpoint = `${listName}=${timesheetStatus.toString()}`;
    }

    const {data} = await api<Timesheet[]>({
        method: 'get',
        url: `Timesheets/review?${endpoint}`,
    });

    return data;
}

export async function approveTimesheet(id: ID): Promise<void> {
    const {data} = await api<void>({
        method: 'post',
        url: `Timesheets/approve/${id}`,
    });

    return data;
}

export async function declineTimesheet(id: ID, declineReason: string): Promise<void> {
    const {data} = await api<void>({
        method: 'post',
        url: `Timesheets/decline/${id}`,
        data: {
            declineReason,
        },
    });

    return data;
}

export async function approveAllTimesheets(): Promise<void> {
    const {data} = await api<void>({
        method: 'post',
        url: 'Timesheets/approve/all',
    });

    return data;
}
