import Vue from 'vue';
import Router from 'vue-router';

import {store} from '@/store';

import HomeScreen from './home/HomeScreen.vue';
import {AuthService} from '@/auth/AuthService';
import MenuLayout from '@/layout/MenuLayout.vue';
import BareLayout from '@/layout/BareLayout.vue';
import { ID } from './shared/store/AbstractEntity';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: MenuLayout,
            meta: {
                requiresAuthentication: true,
            },

            children: [
                {
                    path: '',
                    name: 'home',
                    component: HomeScreen,
                },
                {
                    path: 'employees',
                    name: 'employees',
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "employees" */ './employees/EmployeesScreen.vue');
                    },
                },
                {
                    path: 'employee/:id',
                    name: 'employeeDetails',
                    props: true,
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "employee" */ './employee/employeeDetails/EmployeeDetailsScreen.vue');
                    },
                },
                {
                    path: 'attendance',
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "attendance" */ './attendance/AttendanceScreen.vue');
                    },
                    children: [
                        {
                            path: '',
                            name: 'attendance',
                            async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                                return await import(/* webpackChunkName: "attendance" */ './attendance/Request.vue');
                            },
                        },
                        {
                            path: 'team',
                            name: 'attendance-team',
                            async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                                return await import(/* webpackChunkName: "attendance" */ './attendance/manageable/ManageableScreen.vue');
                            },
                        },
                        {
                            path: 'approve-by-id/:id',
                            async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                                return await import(/* webpackChunkName: "attendance" */ './attendance/manageable/ApproveById.vue');
                            },
                            props(route): {id: ID} {
                                return {
                                    id: parseInt(route.params.id, 10),
                                };
                            },
                        },
                        {
                            path: 'decline-by-id/:id',
                            async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                                return await import(/* webpackChunkName: "attendance" */ './attendance/manageable/DeclineById.vue');
                            },
                            props(route): {id: ID} {
                                return {
                                    id: parseInt(route.params.id, 10),
                                };
                            },
                        },
                    ],
                },
                {
                    path: 'timesheets',
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "timesheets" */ './timesheets/TimesheetsScreen.vue');
                    },

                    children: [
                        {
                            path: '',
                            name: 'timesheets',
                            async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                                return await import(/* webpackChunkName: "timesheets" */ './timesheets/TimesheetsList.vue');
                            },
                        },
                        {
                            path: 'fill/:id',
                            name: 'fillTimesheet',
                            props: true,
                            async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                                return await import(/* webpackChunkName: "timesheets" */ './timesheets/TimesheetEditor.vue');
                            },
                        },
                        {
                            path: 'approve',
                            name: 'timesheets-approve',
                            async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                                return await import(/* webpackChunkName: "timesheets" */ './timesheets/manageable/ManageableScreen.vue');
                            },
                        },
                    ],
                },
                {
                    path: 'structure',
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "structure" */ './structure/StructureScreen.vue');
                    },

                    children: [
                        {
                            name: 'unitsChart',
                            path: 'units',
                            async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                                return await import(/* webpackChunkName: "structure" */ './structure/UnitsChart.vue');
                            },
                        },
                        {
                            name: 'peopleChart',
                            path: 'people',
                            async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                                return await import(/* webpackChunkName: "structure" */ './structure/PeopleChart.vue');
                            },
                        },
                    ],
                },
                {
                    path: 'settings',
                    name: 'settings',
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "settings" */ './settings/SettingsScreen.vue');
                    },
                },
                {
                    path: 'system',
                    name: 'system',
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "system" */ './system/SystemScreen.vue');
                    },
                },
                {
                    path: 'stringEditor',
                    name: 'stringEditor',
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "system" */ './stringEditor/StringEditor.vue');
                    },
                },
                {
                    path: 'timesheetScheduleScreen',
                    name: 'timesheetSchedule',
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "system" */ './system/timesheetSchedule/TimesheetScheduleScreen.vue');
                    },
                },
                {
                    path: 'nationalHolidayScreen',
                    name: 'nationalHoliday',
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "system" */ './system/nationalHoliday/NationalHolidayScreen.vue');
                    },
                },
                {
                    path: 'leaveRequestTypeSpecificFieldsScreen',
                    name: 'leaveRequestTypeSpecificFields',
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "system" */ './system/leaveRequestTypeSpecificFields/LeaveRequestTypeSpecificFieldsScreen.vue');
                    },
                },
                {
                    path: 'fakeZusSynchronizationScreen',
                    name: 'fakeZusSynchronization',
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "system" */ './system/fakeZusSynchronization/FakeZusSynchronizationScreen.vue');
                    },
                },
                {
                    path: 'projects-activities',
                    name: 'projectsActivities',
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "system" */ './system/projectsActivities/ProjectsActivitiesScreen.vue');
                    },
                },
            ],
        },

        {
            path: '/logout',
            component: BareLayout,
            children: [
                {
                    path: '',
                    name: 'logout',
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "logout" */ './auth/LogoutScreen.vue');
                    },
                },
                {
                    path: 'complete',
                    name: 'postLogout',
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "logout" */ './auth/PostLogoutScreen.vue');
                    },
                    meta: {
                        requiresAuthentication: false,
                    },
                },
            ],
        },

        {
            path: '*',
            component: MenuLayout,
            children: [
                {
                    path: '',
                    async component() { // eslint-disable-line @typescript-eslint/explicit-function-return-type
                        return await import(/* webpackChunkName: "404" */ './layout/Error404.vue');
                    },
                },
            ],
        },
    ],
});

router.beforeEach(async (to, from, next): Promise<void> => {
    if (to.matched.some((record): boolean => record.meta.requiresAuthentication)) {
        // this route requires auth, check if logged in
        store.commit('auth/setPending', true);
        const authService = await AuthService.getInstance();
        if (authService.getUser()) {
            // only proceed if authenticated.
            store.commit('auth/setPending', false);
            next();
        } else {
            //prevent starting another `login redirect` flow if we're redirected from one that has been already started
            if (!to.hash.startsWith('#code')){
                authService.loginRedirect();
            }
        }
    } else {
        store.commit('auth/setPending', false);
        next();
    }
});

export {router};
