import {Nullable} from '../types/Nullable';
import {AxiosError} from 'axios';

export type AjaxStateError = Nullable<Error | AxiosError>;

export interface AjaxState {
    isFetching: boolean;
    error: AjaxStateError;
}

export function ajaxStateFactory(): AjaxState {
    return {
        isFetching: false,
        error: null,
    };
}
