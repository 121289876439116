






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class FlippingButton extends Vue {
    @Prop(String)
    icon!: string;

    @Prop(Boolean)
    value!: boolean;
}
