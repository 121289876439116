import {Commit} from 'vuex';
import {AxiosPromise} from 'axios';

import {Resource} from './Resource';
import {
    deleteFromList,
    setCurrent,
    setList,
    updateCurrent,
} from './commonTypes';
import {EntityState} from './EntityState';
import {AbstractEntity, ID} from './AbstractEntity';
import {Nullable} from '../types/Nullable';
import {Converter, defaultConverter} from './Converter';
import {wrapRequest} from './wrapRequest';

export type HermesActionTree<TState> = {
    [key: string]: (context: ActionContext, payload?: any) => any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

type ActionContext = {
    commit: Commit;
}

export function commonActions<TEntity extends AbstractEntity, TState extends EntityState<TEntity>, TResource extends Resource<TEntity>>(
    resource: TResource,
    converter: Converter<TEntity> = (defaultConverter as Converter<TEntity>)
): HermesActionTree<TState> {

    return {
        async list({commit}: ActionContext): Promise<TEntity[] | void> {
            const response = await wrapRequest(commit, (): AxiosPromise<TEntity[]> => {
                return resource.list();
            });

            if (response && response.data) {
                const list = response.data.map(converter.hydrate);
                commit(setList, list);

                return list;
            }
        },

        async read({commit}, id: ID): Promise<TEntity | void> {
            if (id === undefined || id === null) {
                throw new Error('CRUD Read: invalid id');
            }

            const response = await wrapRequest(commit, (): AxiosPromise<TEntity> => {
                return resource.read(id);
            });

            if (response && response.data) {
                const item = converter.hydrate(response.data);
                commit(setCurrent, item);

                return item;
            }
        },

        async patch({commit}, patchedItem: Partial<TEntity>): Promise<TEntity | void > {
            const response = await wrapRequest(commit,  (): AxiosPromise<TEntity> => {
                return resource.patch(converter.dehydrate(patchedItem));
            });

            if (response && response.data) {
                const item = converter.hydrate(response.data);
                commit(updateCurrent, item);

                return item;
            }
        },

        async update({commit}, savedItem): Promise<TEntity | void> {
            const response = await wrapRequest(commit,  (): AxiosPromise<TEntity> => {
                return resource.update(converter.dehydrate(savedItem));
            });

            if (response && response.data) {
                const item = converter.hydrate(response.data);
                commit(updateCurrent, item);

                return item;
            }
        },

        async create({commit}, newItem: Partial<TEntity>): Promise<TEntity | void> {
            const response = await wrapRequest(commit, (): AxiosPromise<TEntity> => {
                return resource.create(converter.dehydrate(newItem));
            });

            if (response && response.data) {
                const item = converter.hydrate(response.data);
                commit(updateCurrent, item);

                return item;
            }
        },

        async delete({commit}, itemToDelete): Promise<void> {
            const response = await wrapRequest(commit, (): AxiosPromise<Nullable<TEntity>> => {
                return resource.delete(itemToDelete.id);
            });

            if (response && response.status < 400) {
                commit(deleteFromList, itemToDelete.id);
            }
        },
    };
}
