export interface Motd {
    text: string;
    author?: string;
}

export abstract class AbstractProvider {
    protected message?: Motd;
    protected expires = 0;

    protected readonly dayDuration = 24 * 60 * 60 * 1000;

    protected constructor(protected key: string) {
        this.load();
    }

    private load(): void {
        const stored = localStorage.getItem(this.key);
        if (stored) {
            const {message, expires} = JSON.parse(stored);
            this.message = message;
            this.expires = expires;
        }
    }

    private save(): void {
        localStorage.setItem(this.key, JSON.stringify({
            message: this.message,
            expires: this.expires,
        }));
    }

    protected abstract async getMessage(): Promise<Motd>;

    public async fetch(): Promise<Motd> {
        if (!this.message || this.expires < Date.now()) {
            this.message = await this.getMessage();
            this.expires = Date.now() + this.dayDuration;
            this.save();
        }

        return this.message;
    }
}
