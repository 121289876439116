import {Component, Vue} from 'vue-property-decorator';

@Component
export class InputAttrs extends Vue {
    protected get inputAttrs(): object {
        return {
            dense: true,
            height: 20,
        };
    }
}
