












import {Component, Prop, Vue} from 'vue-property-decorator';
import {LeaveTypeEnum, LeaveTypeIconEnum} from '@/attendance/store/LeaveTypeEnum';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';

@Component
export default class LeaveTypeIcon extends Vue {
    @Prop({type: Object, default: null})
    item!: LeaveRequest;

    @Prop({type: Number, default: -1})
    iconId!: number;

    @Prop({type: Boolean, default: true})
    large!: boolean;

    @Prop({type: Boolean, default: false})
    noTooltip!: boolean;

    get icon(): string {
        if (this.item) {
            return LeaveTypeIconEnum[this.item.leaveTypeId as LeaveTypeEnum] as string;
        } else if (this.iconId !== -1) {
            return LeaveTypeIconEnum[this.iconId as LeaveTypeEnum];
        } else {
            return 'hourglass_empty';
        }
    }

    get title(): string | undefined {
        return this.item?.leaveSubtypeName;
    }

    get attrs(): object {
        return {
            large: this.large,
            small: !this.large,
        };
    }
}
