import {LeaveRequest} from '@/attendance/store/LeaveRequest';

export const converter = {
    dehydrate(feLeaveRequest: LeaveRequest): LeaveRequest {
        return feLeaveRequest;
    },

    hydrate(beLeaveRequest: LeaveRequest<string>): LeaveRequest {
        const {startDate, endDate, ...params} = beLeaveRequest;

        return {
            ...params,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
        };
    },
};
