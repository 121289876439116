

























import {Component, Mixins, Watch} from 'vue-property-decorator';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {getAbsentToday} from '@/attendance/shared/endpoints';
import HashId from '@/shared/HashId.vue';
import LeaveTypeIcon from '@/attendance/shared/LeaveTypeIcon.vue';
import {AbsentTodayEntry} from '@/attendance/store/AbsentTodayEntry';

enum AbsentType {
    AbsentToday,
    WorkFromHome,
}

@Component({
    components: {
        LeaveTypeIcon,
        HashId,
    },
})
export default class AbsentToday extends Mixins(ApiCall) {
    absentType: AbsentType = AbsentType.AbsentToday;

    rawList: AbsentTodayEntry[] = [];

    async mounted(): Promise<void> {
        this.absentType = parseInt(localStorage.getItem('absentType') || '0', 10);
        await this.apiCall(async () => {
            this.rawList = await getAbsentToday();
        });
    }

    @Watch('absentType')
    onAbsentTypeChange(newValue: AbsentType): void {
        localStorage.setItem('absentType', newValue.toString());
    }
}
