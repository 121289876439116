import {Component, Vue} from 'vue-property-decorator';

@Component({

})
export class DictionaryWidget extends Vue {
    protected get isFetching(): boolean {
        return this.$store.state.dictionaries.isFetching;
    }

    protected async mounted(): Promise<void> {
        await this.fetchAll();
    }

    protected async fetchAll(): Promise<void> {
        await this.$store.dispatch('dictionaries/fetch');
    }
}
