import axios, {AxiosResponse} from 'axios';
import {AbstractProvider, Motd} from './AbstractProvider';

interface JokeResponse {
    joke: string;
}

export class JokeProvider extends AbstractProvider {
    constructor() {
        super('jotd');
    }

    async getMessage(): Promise<Motd> {
        const response: AxiosResponse<JokeResponse> = await axios({
            method: 'get',
            url: 'https://icanhazdadjoke.com/',
            headers: {
                'Accept': 'application/json',
            },
        });

        return {
            text: response.data.joke,
        };
    }
}
