import Vue, {VNode} from 'vue';
import {sync} from 'vuex-router-sync';

import {vuetify} from './plugins/vuetify';
import {router} from './router';
import {store} from './store';
import {i18n} from './plugins/i18n';
import './plugins/meta';
import './plugins/sentry';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VueParticles from 'vue-particles';

import App from './App.vue';

Vue.config.productionTip = false;

sync(store, router);

Vue.use(VueParticles);

export const vueApp = new Vue({
    router,
    store,
    i18n,
    vuetify,
    render(h): VNode {
        return h(App);
    },
}).$mount('#app');
