import {AjaxState} from '@/shared/store/AjaxState';
import {AxiosError} from 'axios';

function isAxiosError(error: Error | AxiosError): error is AxiosError {
    return 'isAxiosError' in error;
}

export const commonGetters = {
    validationErrors(state: AjaxState): string[] {
        const error = state.error;

        if (!error) {
            return [];
        }

        if (isAxiosError(error) && error.response && error.response.data) {
            return Object.values(error.response.data.errors).flat() as string[] ?? [];
        }

        return [];
    },
};
