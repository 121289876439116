






import {Component, Prop, Vue} from 'vue-property-decorator';
import {HermesUser} from '@/employees/store/HermesUser';

@Component
export default class EmploymentStatusBadge extends Vue {
    @Prop(Object)
    readonly user!: HermesUser;

    get color(): string {
        if (this.user.isTerminated) {
            return 'deep-orange darken-1';
        } else {
            return 'teal';
        }
    }

    get icon(): string {
        return this.user.isTerminated ? 'work_off' : 'work';
    }
}
