import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import {BrowserOptions} from '@sentry/browser';

if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [new Integrations.Vue({Vue, attachProps: true})],
        logErrors: true,
    } as BrowserOptions);
}
