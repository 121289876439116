import Vue from 'vue';
import Vuex, {StoreOptions} from 'vuex';
import {RootState} from '@/shared/store/RootState';

import {auth} from '@/auth/store';
import {employees} from '@/employees/store';
import {dictionaries} from '@/dictionaries/store';

Vue.use(Vuex);

const storeOptions: StoreOptions<RootState> = {
    modules: {
        auth,
        employees,
        dictionaries,
    },
};

export const store = new Vuex.Store<RootState>(storeOptions);
