import { loadRuntimeConfig } from '@/shared/loadRuntimeConfig';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

const defaultLocale = 'en';
export const availableLanguages = ['en', 'pl'];

function slavicPluralization(choice: number, choicesLength: number): number {
    if (choice === 0) {
        return 0;
    }

    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;

    if (!teen && endsWithOne) {
        return 1;
    }

    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
    }

    return (choicesLength < 4) ? 2 : 3;
}

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    silentFallbackWarn: process.env.NODE_ENV !== 'development',
    pluralizationRules: {
        pl: slavicPluralization,
    },
});

const loadedLanguages: string[] = [];

function setI18nLanguage(lang: string): string {
    i18n.locale = lang;
    localStorage.setItem('hermes-lang', lang);
    document.querySelector('html')?.setAttribute('lang', lang);

    return lang;
}

export function readSavedLocale(): string {
    return localStorage.getItem('hermes-lang') || loadedLanguages[0];
}

export async function loadLanguageAsync(lang: string): Promise<string> {
    if (!loadedLanguages.includes(lang)) {
        const {blobStorageUrl} = await loadRuntimeConfig();
        const res = await fetch(`${blobStorageUrl}/string-dictionary/${lang}.json`);
        const msgs = await res.json();
        i18n.setLocaleMessage(lang, msgs);
        loadedLanguages.push(lang);
    }
    return Promise.resolve(setI18nLanguage(lang));
}

const initialLocale = readSavedLocale() ?? defaultLocale;
loadLanguageAsync(initialLocale);
