
















import {Component, Vue} from 'vue-property-decorator';
import {apiVersion} from '@/system/store/endpoints';
import {loadRuntimeConfig} from '@/shared/loadRuntimeConfig';

@Component
export default class ServiceFooter extends Vue {
    version = '';

    noBrowserWarning = !!sessionStorage.getItem('noBrowserWarning');

    async mounted(): Promise<void> {
        const apiVersionValue = (await apiVersion()).data.version;
        const feVersion = (await loadRuntimeConfig()).version;
        this.version = `Frontend v${feVersion} || API v${apiVersionValue}`;
    }

    get notChrome(): boolean {
        if ('chrome' in window) {
            // this will also work in Opera and Vivaldi, but that's ok.
            // Edge thinks he's Chrome
            return navigator.userAgent.includes('Edge/');
        } else {
            return true;
        }
    }

    hideBrowserWarning(): void {
        this.noBrowserWarning = true;
        sessionStorage.setItem('noBrowserWarning', 'true');
    }
}
