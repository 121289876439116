export enum LeaveTypeEnum {
    Vacation = 1,
    Sickness,
    BusinessTrip,
    Absence,
    WorkFromHome,
    Unpaid,
}

export const LeaveTypeIconEnum = {
    [LeaveTypeEnum.Vacation]: 'beach_access',
    [LeaveTypeEnum.Sickness]: 'mood_bad',
    [LeaveTypeEnum.Absence]: 'hourglass_empty',
    [LeaveTypeEnum.BusinessTrip]: 'laptop',
    [LeaveTypeEnum.WorkFromHome]: 'home_work',
    [LeaveTypeEnum.Unpaid]: 'money_off',
};
