import axios from 'axios';

export interface RuntimeConfig {
    clientId: string;
    authority: string;
    baseURL: string;
    enableParticles: boolean;
    version: string;
    volatileAuth?: boolean;
    blobStorageUrl?: string;
    benefitsReport?: string;
    graphScopes: string;
    companyId: string;
}

let config: RuntimeConfig;
let promise: Promise<RuntimeConfig>;

export function loadRuntimeConfig(): Promise<RuntimeConfig> {
    if (!config) {
        if (!promise) {
            const url = `./env.json?${Date.now()}`;
            promise = axios.get(url).then((response) => {
                config = {
                    clientId: response.data.VUE_APP_CLIENT_ID,
                    authority: response.data.VUE_APP_TENANT_ID,
                    baseURL: response.data.VUE_APP_API_URL,
                    enableParticles: response.data.VUE_APP_ENABLE_PARTICLES,
                    version: response.data.VUE_APP_VERSION,
                    volatileAuth: response.data.VUE_APP_VOLATILE_AUTH,
                    blobStorageUrl: response.data.VUE_APP_BLOBSTORAGE_URL,
                    benefitsReport: response.data.VUE_APP_BENEFITREPORT_URL,
                    graphScopes: response.data.VUE_APP_GRAPH_SCOPES,
                    companyId: response.data.VUE_APP_COMPANY_ID,
                };
                return config;
            });
        }

        return promise;
    } else {
        return Promise.resolve(config);
    }
}
