import {format} from 'date-fns';

function fmtFactory(fmtStr: string): (date: Date) => string {
    return function (value: Date): string {
        if (!value) {
            return '';
        } else {
            return format(value, fmtStr);
        }
    };
}

export const formatDate = fmtFactory('dd.MM.yyyy');
export const formatDateForDatePicker = fmtFactory('yyyy-MM-dd');
export const formatDateTime = fmtFactory('dd.MM.yy HH:mm');
