export enum LeaveRequestStatus {
    Pending,
    Approved,
    Declined,
    PreApproved,
    Withdrawn,
}

export const LeaveRequestStatusIcon = {
    [LeaveRequestStatus.Pending]: 'contact_support',
    [LeaveRequestStatus.Approved]: 'check_circle',
    [LeaveRequestStatus.Declined]: 'highlight_off',
    [LeaveRequestStatus.PreApproved]: 'check',
    [LeaveRequestStatus.Withdrawn]: 'delete',
};

export const LeaveRequestStatusColor = {
    [LeaveRequestStatus.Pending]: 'warning',
    [LeaveRequestStatus.Approved]: 'success',
    [LeaveRequestStatus.Declined]: 'error',
    [LeaveRequestStatus.PreApproved]: 'success',
    [LeaveRequestStatus.Withdrawn]: 'warning',
};
