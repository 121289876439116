








import {Component, Vue} from 'vue-property-decorator';

@Component
export default class Error403 extends Vue {
    get logoutUrl(): string {
        return this.$router.resolve({name: 'logout'}).href;
    }
}
