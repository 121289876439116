













import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class StatNumber extends Vue {
    @Prop({type: [Number, String]})
    amount!: number | string;

    @Prop(String)
    label!: string;

    @Prop(String)
    dataAutomationId!: string;
}
