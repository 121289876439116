import {MutationTree} from 'vuex';
import {EmployeeState} from './EmployeeState';
import {commonMutations} from '@/shared/store/commonMutations';
import {setSearch, setViewer, setShowTerminated} from './types';

export const mutations: MutationTree<EmployeeState> = {
    ...commonMutations(),

    [setSearch](state, search): void {
        state.quickSearch = search;
    },

    [setViewer](state, viewer): void {
        state.viewer = viewer;
    },

    [setShowTerminated](state, showTerminated): void {
        state.showTerminated = showTerminated;
    },
};
