type LikeFunction = (str: string) => boolean;

export function like(term: string): LikeFunction {
    const termN = term.toLocaleLowerCase().trim();

    return function (str: string): boolean {
        if (!str) {
            return false;
        }

        const strN = str.toLocaleLowerCase().trim();
        return strN.includes(termN);
    };
}
