import axios, {AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig} from 'axios';
import {AuthService} from '@/auth/AuthService';
import {loadRuntimeConfig} from '@/shared/loadRuntimeConfig';
import {vueApp} from '@/main';

let apiInstance: AxiosInstance;

function onFullFilled(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    return AuthService.getInstance().then((authService) => {
        return authService.getGraphToken()
            .then((token): Promise<AxiosRequestConfig> => {
                config.headers.common.Authorization = `Bearer ${token.accessToken}`;
                return Promise.resolve(config);
            });
    });
}

function onRejected(error: AxiosError): Promise<AxiosError> {
    if (error.response) {
        if (error.response.status === 401) {
            vueApp.$emit('HTTP-401');
        } else if (error.response.status === 403) {
            vueApp.$emit('HTTP-403');
        }
    }

    return Promise.reject(error);
}

function createApi(): Promise<AxiosInstance> {
    return loadRuntimeConfig().then((config) => {
        const axiosInstance = axios.create({
            baseURL: config.baseURL,
        });

        axiosInstance.interceptors.request.use(
            onFullFilled,
            console.error.bind(console)
        );

        axiosInstance.interceptors.response.use(
            (response) => response,
            onRejected
        );

        return axiosInstance;
    });
}

export function api<T>(config: AxiosRequestConfig): AxiosPromise<T> {
    if (apiInstance) {
        return apiInstance(config);
    } else {
        return createApi().then((a) => {
            apiInstance = a;
            return apiInstance(config);
        });
    }
}
