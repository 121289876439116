import {Component, Vue} from 'vue-property-decorator';

@Component
export class ResponsiveMenuBtn extends Vue {
    get iconSize(): object {
        return {
            small: this.$vuetify.breakpoint.mdAndDown,
        };
    }

    get buttonAttrs(): object {
        return {
            text: true,
            small: this.$vuetify.breakpoint.lgAndDown,
            depressed: this.$vuetify.breakpoint.lgAndDown,
            icon: this.$vuetify.breakpoint.smAndDown,
        };
    }
}
