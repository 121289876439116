import {Day, DayActivity} from '@/timesheets/store/Timesheet';
import {ID} from '@/shared/store/AbstractEntity';
import {roundValue} from '@/timesheets/shared/roundValue';

type UpdateStrategy = (oldValue: number, newValue: number) => number;

function setStrategy(oldValue: number, newValue: number): number {
    return newValue;
}
function addStrategy(oldValue: number, newValue: number): number {
    return oldValue + newValue;
}

function updateActivityValue(day: Day, activityId: ID, hours: number, overtime: number, strategy: UpdateStrategy): void {
    if (hours < 0 || overtime < 0 || (!isFinite(hours) && !isFinite(overtime))) {
        return;
    }

    hours = roundValue(hours);
    overtime = roundValue(overtime);

    const found = day.activities.find((a) => a.id === activityId);
    if (found) {
        found.hours = strategy(found.hours, hours);
        found.overtime = strategy(found.overtime, overtime);
    } else {
        day.activities.push({
            id: activityId,
            hours: hours,
            note: '',
            overtime: overtime,
        });
    }
}

export function setActivityValues(day: Day, activityId: ID, hours: number, overtime: number): void {
    updateActivityValue(day, activityId, hours, overtime, setStrategy);
}

export function addActivityValues(day: Day, activityId: ID, hours: number, overtime: number): void {
    updateActivityValue(day, activityId, hours, overtime, addStrategy);
}
