

























































































import {Component, InjectReactive, Mixins} from 'vue-property-decorator';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {ActivityType, Project} from '@/dictionaries/projects/store';
import {getProjects, getTimesheetForDate, saveTimesheet} from '@/timesheets/store/endpoints';
import {MeUser} from '@/employees/store/HermesUser';
import {Nullable} from '@/shared/types/Nullable';
import {Day, DayActivity, Timesheet} from '@/timesheets/store/Timesheet';
import {addActivityValues} from '@/timesheets/shared/changeActivityValue';
import {parseHourInput} from '@/timesheets/shared/parseHourInput';
import {roundValue} from '@/timesheets/shared/roundValue';
import ActivitiesList from '@/timesheets/shared/ActivitiesList.vue';
import DistinctCard from '@/shared/DistinctCard.vue';
import {Dict} from '@/shared/types/Dict';
import {activityNamesToMap} from '@/timesheets/shared/activityNamesToMap';
import {isTimesheetEditable} from '@/timesheets/shared/isTimesheetEditable';
import TimesheetStatusIcon from '@/timesheets/shared/TimesheetStatusIcon.vue';
import {getReportedHoursForDay} from '@/timesheets/shared/getReportedHoursForDay';

@Component({
    components: {
        TimesheetStatusIcon,
        DistinctCard,
        ActivitiesList,
    },
})
export default class QuickAdd extends Mixins(ApiCall) {
    @InjectReactive()
    readonly viewer!: MeUser;

    timesheet: Nullable<Timesheet> = null;

    employeeProjects: Project[] = [];
    selectedActivity: Nullable<ActivityType> = null;
    selectedProject: Nullable<Project> = null;

    hours = 0;
    overtime = 0;

    async mounted(): Promise<void> {
        await this.apiCall(this.fetchProjects);

        this.fetchTimesheet();
    }

    async fetchProjects(): Promise<void> {
        this.employeeProjects = await getProjects(this.viewer.id);
    }

    async fetchTimesheet(): Promise<void> {
        this.timesheet = await getTimesheetForDate(this.viewer.id);
    }

    onSelectActivity({project, activity}: {project: Project; activity: ActivityType}): void {
        this.selectedProject = project;
        this.selectedActivity = activity;
    }

    get menuButtonLabel(): string {
        if (this.selectedProject && this.selectedActivity) {
            return `${this.selectedProject.name}: ${this.selectedActivity.name}`;
        } else {
            return this.$t('chooseActivity') as string;
        }
    }

    get currentDay(): Nullable<Day> {
        if (!this.timesheet) {
            return null;
        }
        const today = new Date().getDate();
        const day = this.timesheet.days.find((d) => {
            return d.day === today;
        });

        return day || null;
    }

    get loggedActivities(): number {
        if (!this.currentDay) {
            return 0;
        }
        return getReportedHoursForDay(this.currentDay);
    }

    get activityNames(): Dict<string> {
        if (!this.employeeProjects) {
            return {};
        }

        return activityNamesToMap(this.employeeProjects);
    }

    get isFormDisabled(): boolean {
        if (!this.timesheet) {
            return true;
        } else {
            return !isTimesheetEditable(this.timesheet);
        }
    }

    async onAddClick(): Promise<void> {
        if (!this.timesheet || !this.selectedActivity) {
            return;
        }

        const day = this.currentDay;

        if (day) {
            addActivityValues(day, this.selectedActivity.id, this.hours, this.overtime);

            await saveTimesheet(this.timesheet);

            if (!this.error) {
                this.hours = 0;
                this.selectedActivity = null;
                this.selectedProject = null;
                this.overtime = 0;
            }
        }
    }

    onHoursChange(rawValue: string): void {
        const value = parseHourInput(rawValue);

        if (value !== null) {
            this.hours = roundValue(value);
        }
    }

    onOvertimeChange(rawValue: string): void {
        const value = parseHourInput(rawValue);

        if (value !== null) {
            this.overtime = roundValue(value);
        }
    }

    onHourInputClick(event: MouseEvent): void {
        (event.currentTarget as HTMLInputElement).select();
    }

    getTotalOvertime(activities: DayActivity[]): number {
        let totalOvertime = 0;
        for (const activity of activities) {
            totalOvertime += activity.overtime;
        }
        return totalOvertime;
    }
}
