















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
})
export default class DistinctCard extends Vue {
    @Prop({type: Boolean, default: false})
    noHeader!: boolean;
}
