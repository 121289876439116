import {Commit} from 'vuex';
import {setError, setIsFetching} from './commonTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function wrapRequest(commit: Commit, handler: Function, ...args: any[]): Promise<void | any> {
    commit(setIsFetching, true);

    try {
        const result = await handler(...args);
        commit(setError, null);
        return result;
    } catch (e) {
        commit(setError, e);
    } finally {
        commit(setIsFetching, false);
    }
}
