import {AbstractEntity} from './AbstractEntity';

export interface Converter<TClientEntity extends AbstractEntity = AbstractEntity> {
    // convert from backend to frontend representation
    hydrate(item: Partial<TClientEntity>): TClientEntity;

    // convert from frontend to backend
    dehydrate(item: Partial<TClientEntity>): Partial<TClientEntity>;
}

export const defaultConverter: Converter<AbstractEntity> = {
    hydrate(beItem): AbstractEntity {
        return beItem as AbstractEntity;
    },

    dehydrate(feItem: AbstractEntity): AbstractEntity {
        return feItem;
    },
};
