





























import {Component, Inject, Mixins, Vue} from 'vue-property-decorator';
import {getStats} from '@/attendance/shared/endpoints';
import {Nullable} from '@/shared/types/Nullable';
import {ApiCall} from '@/shared/mixins/ApiCall';
import StatNumber from '@/shared/StatNumber.vue';

@Component({
    components: {
        StatNumber,
    },
})
export default class LeaveStats extends Mixins(ApiCall) {
    stats: Nullable<object> = null;

    @Inject()
    readonly bus!: Vue;

    async mounted(): Promise<void> {
        if (this.bus) {
            this.bus.$on('refreshRequests', this.fetchData);
        }

        await this.fetchData();
    }

    private async fetchData(): Promise<void> {
        await this.apiCall(async () => {
            this.stats = await getStats();
        });
    }
}
