import {ID} from '@/shared/store/AbstractEntity';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';

export interface DayActivity {
    id: ID;
    hours: number;
    note: string;
    overtime: number;
}

export interface CalendarEvent<TDate = Date> {
    start: TDate;
    end: TDate;
    description: string;
    summary: string;
}

export interface Day {
    day: number;
    isHoliday: boolean;
    holidayName: string;
    activities: DayActivity[];
    requiredHours: number;
    leaveRequests?: LeaveRequest[];
    customCalendarEvents: CalendarEvent[];
}

export interface Timesheet {
    id: ID;
    year: number;
    month: number;
    employeeId: ID;
    employeeName?: string;
    status: TimesheetStatus;
    declineReason?: string;
    days: Day[];
    hoursRequired: number;
    hoursReported: number;
    overtimeReported: number;
    leaveRequests: LeaveRequest<string>[];
    submitValidationResult: ValidationResult;
}

export interface TimesheetsBasicViewModelsPaged
{
    timesheets: Timesheet[];
    totalCount: number;
}

export interface ValidationResult {
    isValid: boolean;
    message: string;
}

export enum TimesheetStatus {
    Draft,
    Approved,
    Declined,
    ReadyToApprove,
    ReadyToApproveOrDeclined
}
