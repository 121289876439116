import {LeaveType} from '@/dictionaries/leaveTypes/store';
import {api} from '@/shared/api';
import {ID} from '@/shared/store/AbstractEntity';
import {HistoryEntry, hydrate} from '@/attendance/store/HistoryEntry';
import {LeaveRequest} from '@/attendance/store/LeaveRequest';
import {converter} from '../store/converter';
import {LeaveRequestStatus} from '@/attendance/store/LeaveRequestStatus';
import {uniqueBy} from '@/shared/uniqueBy';
import {AbsentTodayEntry} from '@/attendance/store/AbsentTodayEntry';
import {sortBy} from '@/shared/sortBy';
import {AbsenceChartFilter, AbsencesResponse, LeaveSeriesDatum} from '@/attendance/store/ChartCalendarAbsences';

export async function sendRequest(data: FormData): Promise<LeaveRequest> {
    const response = await api<LeaveRequest<string>>({
        method: 'post',
        url: 'LeaveRequests',
        data,
    });

    return converter.hydrate(response.data) as LeaveRequest;
}

export async function getLeaveTypes(): Promise<LeaveType[]> {
    const response = await api<LeaveType[]>({
        url: 'Employees/leaveTypes',
    });

    return response.data;
}

export async function getRequestHistory(id: ID): Promise<HistoryEntry<Date>[]> {
    const response = await api<HistoryEntry[]>({
        url: `LeaveRequests/history/${id}`,
    });

    return response.data.map(hydrate);
}

export async function getManageableRequests(pending = false): Promise<LeaveRequest[]> {
    const response = await api<LeaveRequest<string>[]>({
        url: `LeaveRequests/manageable/${pending ? 'unresolved' : 'resolved'}`,
    });

    return response.data.map(converter.hydrate);
}

export async function getLeaveRequest(id: ID): Promise<LeaveRequest> {
    const response = await api<LeaveRequest<string>>({
        method: 'get',
        url: `LeaveRequests/${id}`,
    });

    return converter.hydrate(response.data);
}

export async function declineRequest(id: ID, reason: string): Promise<void> {
    await api({
        method: 'post',
        url: `LeaveRequests/decline/${id}`,
        data: {
            declineReason: reason,
        },
    });
}

export async function approveRequest(id: ID): Promise<void> {
    await api({
        method: 'post',
        url: `LeaveRequests/approve/${id}`,
    });
}

export async function getMyRequests(employeeId: ID): Promise<LeaveRequest[]> {
    const response = await api<LeaveRequest<string>[]>({
        method: 'get',
        url: `LeaveRequests/employee/${employeeId}`,
    });

    return response.data.map(converter.hydrate);
}

export async function withdrawRequest(id: ID): Promise<void> {
    await api({
        method: 'delete',
        url: `LeaveRequests/${id}`,
    });
}

export async function approveAllRequests(): Promise<void> {
    await api({
        method: 'post',
        url: 'LeaveRequests/approve/all',
    });
}

export async function uploadAttachments(id: ID, attachments: File[]): Promise<LeaveRequest> {
    const formData = new FormData();

    for (const file of attachments) {
        formData.append('attachments', file);
    }

    const url = `LeaveRequests/appendAttachment/${id}`;

    const response = await api<LeaveRequest<string>>({
        method: 'post',
        url,
        data: formData,
    });

    return converter.hydrate(response.data) as LeaveRequest;
}


export async function getAbsencesChart(filter: AbsenceChartFilter): Promise<AbsencesResponse> {
    const {data} = await api<LeaveRequest<string>[]>({
        url: 'LeaveRequests/team',
        params: filter,
    });

    const hydratedData = data.map(converter.hydrate).filter((lr: LeaveRequest) => {
        return lr.leaveRequestStatus !== LeaveRequestStatus.Declined && lr.leaveRequestStatus !== LeaveRequestStatus.Withdrawn;
    });

    const employees: string[] = ['']; // empty entry to create a padding at the bottom of chart (echarts render x-axis from bottom)
    const absences: LeaveSeriesDatum[] = [];

    for (const lr of hydratedData) {
        let index = employees.indexOf(lr.employeeName);
        if (index === -1) {
            index = employees.length;
            employees.push(lr.employeeName);
        }

        absences.push([
            lr.employeeId,
            lr.employeeName,
            lr.startDate.getTime(),
            lr.endDate.getTime(),
            index,
            lr.leaveRequestStatus,
        ]);
    }

    return {
        employees: {
            data: employees,
            dimensions: ['Name'],
        },
        absences: {
            data: absences,
            dimensions: ['ID', 'Name', 'Start date', 'End date', 'Index', 'Status'],
        },
    };
}

export async function getAbsentToday(): Promise<AbsentTodayEntry[]> {
    const response = await api<AbsentTodayEntry<string>[]>({
        url: 'LeaveRequests/absentToday',
    });

    return sortBy(uniqueBy(response.data, 'employeeId'), 'employeeName');
}

export async function getStats(): Promise<object> {
    const {data} = await api<object>({
        url: 'LeaveRequests/absenceStats',
    });

    return data;
}
