
















import Vue from 'vue';
import {Component, Provide} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {MetaInfo} from 'vue-meta';

import ServiceFooter from '@/layout/ServiceFooter.vue';
import Error403 from '@/layout/Error403.vue';
import Error401 from '@/layout/Error401.vue';
import {MessageColor} from '@/shared/mixins/InjectShowMessage';
import {LocaleMessages} from 'vue-i18n';
import {loadRuntimeConfig} from '@/shared/loadRuntimeConfig';

const auth = namespace('auth');

@Component({
    components: {
        Error401,
        Error403,
        ServiceFooter,
    },

    metaInfo(): MetaInfo {
        return {
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
        };
    },
})
export default class App extends Vue {
    @auth.State
    pending!: boolean;

    has401 = false;
    has403 = false;

    message: string | LocaleMessages = '';
    color: MessageColor = 'success';
    displaySnackbar = false;

    @Provide()
    showMessage(message: string | LocaleMessages, color: MessageColor): void {
        this.displaySnackbar = true;
        this.message = message;
        this.color = color;
    }

    async mounted(): Promise<void> {
        const config = await loadRuntimeConfig();
        if (config.companyId === 'Integrity') {
            const favicon = document.getElementById('favicon') as HTMLLinkElement;
            favicon.href = '/favicon-integrity-v1.png';
        }

        this.$root.$on('HTTP-401', this.on401);
        this.$root.$on('HTTP-403', this.on403);
    }

    on401(): void {
        this.has401 = true;
    }

    on403(): void {
        this.has403 = true;
    }
}
