




























import {Component, Prop, Mixins, InjectReactive} from 'vue-property-decorator';

import {HermesUser, MeUser} from '@/employees/store/HermesUser';
import EmploymentStatusBadge from '@/employee/employeeDetails/EmploymentStatusBadge.vue';
import {extractTel} from '@/shared/extractTel';
import {Nullable} from '@/shared/types/Nullable';
import ColWidth from '@/shared/mixins/ColWidth';
import HashId from '@/shared/HashId.vue';

@Component({
    components: {
        HashId,
        EmploymentStatusBadge,
    },
})
export default class EmployeeShortCard extends Mixins(ColWidth) {
    @Prop(Object)
    readonly user!: HermesUser;

    @InjectReactive()
    viewer!: MeUser;

    @Prop({type: Boolean, default: false})
    fullWidth!: boolean;

    protected get emailLink(): string {
        return `mailto:${this.user.email}`;
    }

    protected get tel(): Nullable<string> {
        return extractTel(this.user);
    }

    protected get telLink(): Nullable<string> {
        return this.tel && `tel:${this.tel}`;
    }
}
