
























import {Component, Vue, Watch} from 'vue-property-decorator';
import {QuoteProvider} from '@/motd/store/QuoteProvider';
import {JokeProvider} from '@/motd/store/JokeProvider';
import {Motd} from '@/motd/store/AbstractProvider';
import {Nullable} from '@/shared/types/Nullable';

enum MotdType {
    Quote,
    Joke
}

@Component
export default class MotdWidget extends Vue {
    msgType: MotdType = MotdType.Quote;

    message: Nullable<Motd> = null;

    quoteProvider!: QuoteProvider;
    jokeProvider!: JokeProvider;

    async mounted(): Promise<void> {
        this.msgType = localStorage.getItem('motdType') ? MotdType.Joke : MotdType.Quote;

        this.quoteProvider = new QuoteProvider();
        this.jokeProvider = new JokeProvider();

        await this.getMessage();
    }

    async getMessage(msgType = this.msgType): Promise<void> {
        if (msgType === MotdType.Quote) {
            this.message = await this.quoteProvider.fetch();
        } else {
            this.message = await this.jokeProvider.fetch();
        }
    }

    @Watch('msgType')
    onMsgTypeChange(newVal: MotdType): void {
        this.getMessage(newVal);

        if (newVal === MotdType.Quote) {
            localStorage.removeItem('motdType');
        } else {
            localStorage.setItem('motdType', '1');
        }
    }
}
