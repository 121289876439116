















import {Component, Mixins, ProvideReactive} from 'vue-property-decorator';
import {namespace} from 'vuex-class';

import TopMenu from '@/navigation/TopMenu.vue';
import {Nullable} from '@/shared/types/Nullable';
import {MeUser} from '@/employees/store/HermesUser';
import { DictionaryWidget } from '@/shared/mixins/DictionaryWidget';

const employees = namespace('employees');

@Component({
    components: {
        TopMenu,
    },
})
export default class MenuLayout extends Mixins(DictionaryWidget) {
    @ProvideReactive()
    viewer: Nullable<MeUser> = null;

    @employees.Action
    getViewer!: Function;

    async mounted(): Promise<void> {
        this.viewer = await this.getViewer();
    }
}
