








import {Component, Prop, Vue} from 'vue-property-decorator';
import FlippingButton from '@/shared/FlippingButton.vue';

@Component({
    components: {
        FlippingButton,
    },
})
export default class ExpandButton extends Vue {
    @Prop(Boolean)
    value!: boolean;
}
