import {Dict} from '@/shared/types/Dict';
import {Project} from '@/dictionaries/projects/store';

export function activityNamesToMap(projects: Project[]): Dict<string> {
    const names: Dict<string> = {};

    for (const proj of projects) {
        for (const activity of proj.activities) {
            names[activity.id] = `${proj.name}: ${activity.name}`;
        }
    }

    return names;
}
