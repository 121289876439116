// JSON.stringify omits the timezone, so the date looses a difference between the UTC and a local TZ.
// Similar thing happens when we parse a date from server, times are shown as if (local TZ) earlier.

import {add} from 'date-fns';

export function forServer(date: Date): Date {
    const minutes = date.getTimezoneOffset();
    return add(date, {minutes: -minutes});
}

export function forClient(date: string): Date {
    const parsedDate = new Date(date);
    const minutes = parsedDate.getTimezoneOffset();
    return add(parsedDate, {minutes: -minutes});
}
