import {dateToYearStart} from '@/shared/date/dateToYearStart';
import {dateToYearEnd} from '@/shared/date/dateToYearEnd';
import {DateRange} from '@/shared/types/DateRange';

export interface Filters extends DateRange {
    search: string;
}

export function filtersFactory(overrides: Partial<Filters> = {}): Filters {
    return {
        startDate: dateToYearStart(),
        endDate: dateToYearEnd(),
        search: '',
        ...overrides,
    };
}
