



































import 'echarts/lib/component/tooltip';

import {getAbsencesChart} from '@/attendance/shared/endpoints';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {Component, Mixins, Prop} from 'vue-property-decorator';
import {AbsencesResponse, CalendarEvent, LeaveSeriesDatum} from '@/attendance/store/ChartCalendarAbsences';

@Component
export default class TeamCalendar extends Mixins(ApiCall) {
    @Prop(Boolean)
    readonly!: boolean;

    absences!: AbsencesResponse;
    events: CalendarEvent[] = [];
    type = 'month';
    mode = 'stack';
    weekday = [1, 2, 3, 4, 5, 6, 0];
    value = '';
    colors = ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'];

    mounted(): void {
        this.apiCall(async () => {
            await this.fetchData();
        });
    }

    async fetchData(): Promise<void> {
        this.absences = await getAbsencesChart({
            startDate: undefined,
            endDate: null,
        });
        const events: CalendarEvent[] = [];
        for (let i = 0; i < this.absences.absences.data.length; i++) {
            const event: LeaveSeriesDatum = this.absences.absences.data[i];

            let startDate = new Date(event[2]);
            startDate = new Date(startDate.setDate(startDate.getDate()));
            const start: string = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0];

            let endDate = new Date(event[3]);
            endDate = new Date(endDate.setDate(endDate.getDate()));
            const end: string = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0];

            events.push({
                name: event[1],
                start: start,
                end: end,
                color: this.colors[this.rnd(0, this.colors.length - 1)],
                timed: true,
            });
        }
        this.events = events;
    }

    getEventColor (event: CalendarEvent): string {
        return event.color;
    }

    rnd (a: number, b: number): number {
        return Math.floor((b - a + 1) * Math.random()) + a;
    }
}

