


















import {Component, Mixins, Prop} from 'vue-property-decorator';
import {IsFullscreen} from '@/shared/mixins/IsFullscreen';
import DistinctCard from '@/shared/DistinctCard.vue';

@Component({
    components: {
        DistinctCard,
    },
})
export default class AppDialog extends Mixins(IsFullscreen) {
    @Prop(Boolean)
    value!: boolean;

    @Prop({type: Boolean, default: true})
    persistent!: boolean;

    clickedOutside(): void {
        if (!this.persistent) {
            this.$emit('click:outside');
        }
    }
}
