import {AxiosPromise, AxiosRequestConfig} from 'axios';
import {Nullable} from '../types/Nullable';
import {AbstractEntity, ID} from './AbstractEntity';

export type ResourceRequest = <T>(config: AxiosRequestConfig) => AxiosPromise<T>;

/**
 * CRUD wrapper for simple REST-like API.
 * Supports nested resources.
 */
export class Resource<T extends AbstractEntity = AbstractEntity> {
    protected readonly request: ResourceRequest;
    protected readonly resource: string;
    protected readonly parent: Nullable<Resource>;

    public constructor(request: ResourceRequest, resource: string, parent = null) {
        this.request = request;
        this.resource = resource;

        this.parent = parent;
    }

    protected getUrl(id?: ID, parentId?: ID): string {
        let url = this.resource;

        if (id !== undefined) {
            url = `${url}/${id}`;
        }

        if (this.parent && parentId !== undefined) {
            url = `${this.parent.getUrl(parentId)}/${url}`;
        }

        return url.replace(/\/\//mg, '/');
    }

    public list(filter = {}, parentId?: ID): AxiosPromise<T[]> {
        return this.request({
            method: 'get',
            url: this.getUrl(undefined, parentId),
            params: filter,
        });
    }

    public create(data: Partial<T>, parentId?: ID): AxiosPromise<T> {
        return this.request({
            method: 'post',
            url: this.getUrl(undefined, parentId),
            data,
        });
    }

    public read(id?: ID, parentId?: ID): AxiosPromise<T> {
        return this.request({
            method: 'get',
            url: this.getUrl(id, parentId),
        });
    }

    public update(data: Partial<T>, parentId?: ID): AxiosPromise<T> {
        return this.request({
            method: 'put',
            url: this.getUrl(data.id, parentId),
            data,
        });
    }

    public delete(id: ID, parentId?: ID): AxiosPromise<T> {
        return this.request({
            method: 'delete',
            url: this.getUrl(id, parentId),
        });
    }

    public patch(data: Partial<T>, parentId?: ID): AxiosPromise<T> {
        const {id, ...patch} = data;
        return this.request({
            method: 'patch',
            url: this.getUrl(id, parentId),
            data: patch,
        });
    }
}
