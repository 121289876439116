













import {Component, Mixins, Prop} from 'vue-property-decorator';
import {ResponsiveMenuBtn} from '@/shared/mixins/ResponsiveMenuBtn';

@Component({
})
export default class TopMenuItem extends Mixins(ResponsiveMenuBtn) {
    @Prop(String)
    automationId!: string;

    @Prop(String)
    icon!: string;

    @Prop(String)
    label!: string;
}
