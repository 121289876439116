























import {Component, Mixins} from 'vue-property-decorator';
import {IsFullscreen} from '@/shared/mixins/IsFullscreen';
import AppDialog from '@/shared/AppDialog.vue';

@Component({
    components: {
        AppDialog,
    },
})
export default class Error401 extends Mixins(IsFullscreen) {
    get logoutUrl(): string {
        return this.$router.resolve({name: 'logout'}).href;
    }
}
