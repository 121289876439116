import {AjaxState, ajaxStateFactory} from '@/shared/store/AjaxState';

import {ContractType} from '@/dictionaries/contractTypes/store';
import {Country} from '@/dictionaries/countries/store';
import {IdDocumentType} from '@/dictionaries/IdDocumentTypes/store';
import {LeaveType} from '@/dictionaries/leaveTypes/store';
import {OrganizationalUnitType} from '@/dictionaries/organizationalUnitType/store';
import {NfzUnit} from '@/dictionaries/nfzUnits/store';
import {TerminationReason} from '@/dictionaries/terminationReasons/store';
import {ActionTree, GetterTree, MutationTree} from 'vuex';
import {Nullable} from '@/shared/types/Nullable';
import {wrapRequest} from '@/shared/store/wrapRequest';
import {AxiosPromise} from 'axios';
import {RootState} from '@/shared/store/RootState';
import {api} from '@/shared/api';
import {ActivityType, Project} from '@/dictionaries/projects/store';
import { HandicapLevel } from './handicapLevel/store';
import { ZusRelationshipType } from './zusRelationshipType/store';
import { FileExtension } from './fileExtensions/store';

export interface Dictionaries {
    contractTypes: ContractType[];
    countries: Country[];
    documentTypes: IdDocumentType[];
    leaveTypes: LeaveType[];
    organizationalUnitTypes: OrganizationalUnitType[];
    projects: Project[];
    nfzUnits: NfzUnit[];
    terminationReasons: TerminationReason[];
    handicapLevels: HandicapLevel[];
    zusRelationshipTypes: ZusRelationshipType[];
    fileExtensions: FileExtension[];
}

interface DictionariesState extends AjaxState, Dictionaries {
    gotList: boolean;
}

function dictionariesStateFactory(): DictionariesState {
    return {
        ...ajaxStateFactory(),

        gotList: false,

        contractTypes: [],
        countries: [],
        documentTypes: [],
        leaveTypes: [],
        organizationalUnitTypes: [],
        projects: [],
        nfzUnits: [],
        terminationReasons: [],
        handicapLevels: [],
        zusRelationshipTypes: [],
        fileExtensions: [],
    };
}

const dictionariesMutations: MutationTree<DictionariesState> = {
    setError(state: DictionariesState, error: Nullable<Error>) {
        state.error = error;
    },

    setIsFetching(state: DictionariesState, isFetching: boolean) {
        state.isFetching = isFetching;
    },

    setDictionaries(state: DictionariesState, dictionaries: Dictionaries) {
        Object.assign(state, dictionaries);
        state.gotList = true;
    },
};

const dictionaryActions: ActionTree<DictionariesState, RootState> = {
    async fetch(context): Promise<Dictionaries | void> {
        if (context.state.gotList || context.state.isFetching) {
            return context.state;
        } else {
            const response = await wrapRequest(context.commit, (): AxiosPromise<Dictionaries> => {
                return api({
                    method: 'GET',
                    url: 'CommonDictionary',
                });
            });

            if (response && response.data) {
                context.commit('setDictionaries', response.data);
                return response.data;
            }
        }
    },
};

const dictionaryGetters: GetterTree<DictionariesState, RootState> = {
    blockingLeaveSubtypes(state): string[] {
        if (state.leaveTypes.length === 0) {
            return [];
        }

        return state.leaveTypes.reduce<string[]>((acc, leaveType) => {
            const blockingSubtypes = leaveType.subtypes.filter((lst) => lst.isBlockingTimesheet);

            if (blockingSubtypes.length) {
                acc.push(
                    ...blockingSubtypes.map(lst => lst.name)
                );
            }

            return acc;
        }, []);
    },

    flatActivities(state): ActivityType[] {
        if (state.projects.length === 0) {
            return [];
        }

        return state.projects.reduce<ActivityType[]>((acc, project) => {
            acc.push(...project.activities);
            return acc;
        }, []);
    },
};



export const dictionaries = {
    namespaced: true,
    state: dictionariesStateFactory(),
    actions: dictionaryActions,
    mutations: dictionariesMutations,
    getters: dictionaryGetters,
};
