




import {Component, Vue, Prop} from 'vue-property-decorator';
import {ID} from '@/shared/store/AbstractEntity';

@Component
export default class HashId extends Vue {
    @Prop(Number)
    hashId!: ID;
}
