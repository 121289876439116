
































import {Component, Vue} from 'vue-property-decorator';
import {MetaInfo} from 'vue-meta';

import AppScreen from '@/layout/AppScreen.vue';
import QuickAdd from '@/timesheets/QuickAdd.vue';
import HmCol from '@/shared/HmCol.vue';
import LeaveStats from '@/attendance/LeaveStats.vue';
import TeamChart from '@/attendance/manageable/TeamChart.vue';
import TeamCalendar from '@/attendance/manageable/TeamCalendar.vue';
import MotdWidget from '@/motd/MotdWidget.vue';
import BirthdayPeople from '@/employees/BirthdayPeople.vue';
import AbsentToday from '@/attendance/AbsentToday.vue';
import {loadHiddenWidgets} from '@/home/store';

@Component({
    components: {
        TeamCalendar,
        AbsentToday,
        BirthdayPeople,
        MotdWidget,
        TeamChart,
        LeaveStats,
        HmCol,
        QuickAdd,
        AppScreen,
    },
    metaInfo(): MetaInfo {
        return {
            title: 'HERMES',
        };
    },
})
export default class HomeScreen extends Vue {
    hiddenWidgets: string[] = [];

    created(): void {
        this.hiddenWidgets = loadHiddenWidgets();
    }
}
