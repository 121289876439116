









































import {Component, Mixins, Prop} from 'vue-property-decorator';
import ObjectVModel from '@/shared/mixins/ObjectVModel';
import HmCol from '@/shared/HmCol.vue';
import DatePicker from '@/shared/date/DatePicker.vue';
import {dateToYearStart} from '@/shared/date/dateToYearStart';
import {dateToYearEnd} from '@/shared/date/dateToYearEnd';
import {Filters} from './types/Filters';

@Component({
    components: {
        DatePicker,
        HmCol,
    },
})
export default class FilterForm extends Mixins(ObjectVModel) {
    private readonly defaultStart = dateToYearStart();
    private readonly defaultEnd = dateToYearEnd();

    @Prop(Object)
    value!: Filters;

    @Prop(Boolean)
    expanded!: boolean;

    get errors(): string[] {
        if (this.value.startDate > this.value.endDate) {
            return [this.$t('startDateExceedsEndDate') as string];
        } else {
            return [];
        }
    }
}
