import {LeaveRequestStatus} from '@/attendance/store/LeaveRequestStatus';
import {ID} from '@/shared/store/AbstractEntity';
import {Nullable} from '@/shared/types/Nullable';

export interface HistoryEntry<TTimeStamp = string> {
    id: ID;
    status: LeaveRequestStatus;
    timestamp: TTimeStamp;
    declineReason: Nullable<string>;
    issuerName: string;
}

export function hydrate(entryBE: HistoryEntry<string>): HistoryEntry<Date> {
    return {
        ...entryBE,
        timestamp: new Date(entryBE.timestamp),
    };
}
