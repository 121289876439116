













import {Component, Prop, Vue} from 'vue-property-decorator';
import {Timesheet, TimesheetStatus} from '@/timesheets/store/Timesheet';

@Component
export default class TimesheetStatusIcon extends Vue {
    @Prop(Object)
    timesheet!: Timesheet;

    TimesheetStatus = TimesheetStatus;

    get icon(): string {
        const status = this.timesheet.status;

        switch (status) {
        case TimesheetStatus.Draft:
            return 'watch_later';
        case TimesheetStatus.Approved:
            return 'check_circle_outline';
        case TimesheetStatus.Declined:
            return 'error_outline';
        case TimesheetStatus.ReadyToApprove:
            return 'contact_support';
        }

        return '';
    }

    get color(): string {
        const status = this.timesheet.status;

        switch (status) {
        case TimesheetStatus.Draft:
            return 'blue-grey darken-2';
        case TimesheetStatus.Approved:
            return 'success';
        case TimesheetStatus.Declined:
            return 'error';
        case TimesheetStatus.ReadyToApprove:
            return 'warning';
        }

        return 'warning';
    }
}
