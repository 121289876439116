import {ActionTree} from 'vuex';
import {EmployeeState} from './EmployeeState';
import {RootState} from '@/shared/store/RootState';
import {api} from '@/shared/api';
import {commonActions} from '@/shared/store/commonActions';
import {EmployeeResource} from '@/employees/store/EmployeeResource';
import {AxiosPromise} from 'axios';
import {AbstractEntity} from '@/shared/store/AbstractEntity';
import {setViewer} from '@/employees/store/types';
import {wrapRequest} from '@/shared/store/wrapRequest';
import {MeUser} from '@/employees/store/HermesUser';
import {converter} from '@/employees/store/converter';
import {addToList} from '@/shared/store/commonTypes';

const resource = new EmployeeResource(api, 'Employees');

export const actions: ActionTree<EmployeeState, RootState> = {
    ...commonActions<AbstractEntity, EmployeeState, EmployeeResource>(resource, converter),

    async register(context, graphId): Promise<AbstractEntity | void> {
        const response = await wrapRequest(context.commit, (): AxiosPromise<AbstractEntity> => {
            return resource.register(graphId);
        });

        if (response && response.data) {
            const item = converter.hydrate(response.data);
            context.commit(addToList, item);
            return item;
        } else {
            throw new Error();
        }
    },

    async getViewer(context): Promise<MeUser | void> {
        const response = await wrapRequest(context.commit, (): AxiosPromise<MeUser> => {
            return resource.readViewer();
        });

        if (response && response.data) {
            const viewer = response.data;
            context.commit(setViewer, viewer);

            return viewer;
        }
    },
};
