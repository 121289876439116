













import {Component, Prop, Vue} from 'vue-property-decorator';
import {ActivityType, Project} from '@/dictionaries/projects/store';
import {sortBy} from '@/shared/sortBy';

@Component
export default class ActivitiesList extends Vue {
    @Prop(Array)
    projects!: Project[];

    get nonBlockedActivities(): Project[] {
        const projects = this.projects.reduce<Project[]>((acc, project) => {
            const {activities, ...meta} = project;
            const filteredActivities = activities.filter((activityType) => !activityType.isBlocked);
            if (filteredActivities.length) {
                acc.push({
                    ...meta,
                    activities: sortBy(filteredActivities, 'order'),
                });
            }

            return acc;
        }, []);

        return sortBy(projects, 'name');
    }

    onActivityClick(project: Project, activity: ActivityType): void {
        this.$emit('selectActivity', {project, activity});
    }
}
