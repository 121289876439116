import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {EmployeeState} from '@/employees/store/EmployeeState';
import {RootState} from '@/shared/store/RootState';

export const state: EmployeeState = {
    error: null,
    isFetching: false,
    list: [],
    current: null,
    viewer: null,
    quickSearch: '',
    showTerminated: false,
};

export const employees: Module<EmployeeState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
