
































































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import TopMenuItem from './TopMenuItem.vue';
import EmployeeMenu from '../employee/employeeDetails/EmployeeMenu.vue';
import {loadRuntimeConfig, RuntimeConfig} from '@/shared/loadRuntimeConfig';
import { InjectViewer } from '@/shared/mixins/InjectViewer';
import { ResponsiveMenuBtn } from '@/shared/mixins/ResponsiveMenuBtn';

@Component({
    components: {
        EmployeeMenu,
        TopMenuItem,
    },
})
export default class TopMenu extends Mixins(InjectViewer, ResponsiveMenuBtn) {
    enableParticles = false;
    companyId = '';

    mounted(): void {
        loadRuntimeConfig().then((config: RuntimeConfig) => {
            this.enableParticles = config.enableParticles;
            this.companyId = config.companyId;
        });
    }
}
