






import {Component, Vue, Prop} from 'vue-property-decorator';

@Component
export default class AppScreen extends Vue {
    @Prop(Boolean)
    fullWidth!: boolean;
}
