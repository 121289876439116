import {api} from '@/shared/api';
import { ID } from '@/shared/store/AbstractEntity';
import {AxiosResponse} from 'axios';
import { FileExtension } from './FileExtension';

export function apiVersion(): Promise<AxiosResponse<ApiVersion>> {
    return api<ApiVersion>({
        url: 'version',
        method: 'get',
    });
}

export function synchronize(): Promise<AxiosResponse> {
    return api({
        url: 'Synchronize',
        method: 'post',
    });
}

export function invalidateCache(): Promise<AxiosResponse> {
    return api({
        method: 'post',
        url: 'invalidateCache',
    });
}

export async function getBgJobs(): Promise<object[]> {
    const {data} = await api<object[]>({
        method: 'get',
        url: 'backgroundJobs',
    });

    return data;
}

interface ApiVersion {
    version: string;
}

export async function getFileExtensions(): Promise<FileExtension[]> {
    const {data} = await api<FileExtension[]>({
        method: 'get',
        url: 'system/fileExtensions',
    });

    return data;
}

export async function addFileExtensions(extension: string): Promise<FileExtension> {
    const {data} = await api<FileExtension>({
        method: 'post',
        url: 'system/fileExtensions',
        data: {suffix: extension},
    });

    return data;
}

export async function removeExtension(id: ID): Promise<void> {
    await api<ID>({
        method: 'delete',
        url: `system/fileExtensions/${id}`,
    });
}