














import {Component, Mixins} from 'vue-property-decorator';
import EmployeeShortCard from '@/employee/employeeDetails/EmployeeShortCard.vue';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {BackendUser, HermesUser} from '@/employees/store/HermesUser';
import {api} from '@/shared/api';
import { converter } from './store/converter';

@Component({
    components: {
        EmployeeShortCard,
    },
})
export default class BirthdayPeople extends Mixins(ApiCall) {
    employees: HermesUser[] = [];

    async getBirthdayPeople(): Promise<void> {
        const response = await api<BackendUser<string>[]>({
            method: 'get',
            url: 'Employees/birthday',
        });

        if (response && response.data) {
            this.employees = response.data.map(converter.hydrate) as HermesUser[];
        }
    }

    async mounted(): Promise<void> {
        await this.apiCall(this.getBirthdayPeople);
    }
}
