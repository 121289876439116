<template>
    <v-sheet elevation="2" tile class="mb-4 teamChart" :class="readonly ? 'pa-2' : 'pa-4'">
        <v-progress-linear absolute indeterminate :active="isFetching"/>
        <header class="headline " :class="readonly ? 'pl-2 pb-2 pt-4' : 'mb-5'">
            {{ $t('teamAbsences') }}
            <expand-button v-model="filtersExpanded" class="hidden-lg-and-up"/>
        </header>
        <filter-form v-if="!readonly" v-model="filters" :expanded="filtersExpanded"/>
        <e-charts v-if="hasChart" :options="absenceChart" :autoresize="true"/>
        <v-alert v-else color="primary" outlined>{{ $vuetify.lang.t('$vuetify.noDataText') }}</v-alert>
    </v-sheet>
</template>

<!-- vue-echart doesn't work with typescript, so we use JS components -->
<script>
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import ECharts from 'vue-echarts';
import 'echarts/lib/component/tooltip';
import echarts from 'echarts';
import {add} from 'date-fns';
import debounce from 'lodash.debounce';

import {getAbsencesChart} from '@/attendance/shared/endpoints';
import {formatDate} from '@/shared/date/formatDate';
import {LeaveRequestStatus} from '@/attendance/store/LeaveRequestStatus';
import FilterForm from '@/shared/FilterForm';
import {filtersFactory} from '@/shared/types/Filters';
import {ApiCall} from '@/shared/mixins/ApiCall';
import ExpandButton from '@/shared/ExpandButton.vue';
import {FiltersExpanded} from '@/shared/mixins/FiltersExpanded';

const DIM_ID = 0;
const DIM_NAME = 1;
const DIM_START_DATE = 2;
const DIM_END_DATE = 3;
const DIM_INDEX = 4;
const DIM_LEAVE_STATUS = 8;

function clipRectByRect(params, rect) {
    return echarts.graphic.clipRectByRect(rect, {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
    });
}

export default {
    name: 'TeamChart',

    components: {
        ExpandButton,
        FilterForm,
        ECharts,
    },

    mixins: [
        ApiCall,
        FiltersExpanded,
    ],

    props: {
        readonly: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        const startDate = new Date();
        const endDate = add(new Date(), {months: 3});

        return {
            absences: null,
            filters: filtersFactory({
                startDate,
                endDate,
            }),
        };
    },

    computed: {
        _dataZoom() {
            const dataLength = this.absences.employees.data.length;
            let sliderStart = dataLength <= 5 ? 0 : 80;
            if (dataLength < 5 || this.readonly) {
                sliderStart = 0;
            } else if (dataLength > 40) {
                sliderStart = 80;
            } else {
                sliderStart = 80 * (dataLength / (40 - 5));
            }

            return [
                {
                    type: 'slider',
                    xAxisIndex: 0,
                    filterMode: 'weakFilter',
                    height: 20,
                    bottom: 0,
                    start: 0,
                    end: 25,
                    handleIcon: 'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    showDetail: false,
                },
                {
                    type: 'inside',
                    id: 'insideX',
                    xAxisIndex: 0,
                    filterMode: 'weakFilter',
                    start: 0,
                    end: 25,
                    zoomOnMouseWheel: false,
                    moveOnMouseMove: true,
                },

                {
                    type: 'slider',
                    yAxisIndex: 0,
                    zoomLock: true,
                    width: 10,
                    right: 10,
                    top: 40,
                    bottom: 20,
                    start: sliderStart,
                    end: 100,
                    handleSize: 0,
                    showDetail: false,
                },
                {
                    type: 'inside',
                    id: 'insideY',
                    yAxisIndex: 0,
                    start: 95,
                    end: 100,
                    zoomOnMouseWheel: false,
                    moveOnMouseMove: true,
                    moveOnMouseWheel: true,
                },
            ];
        },

        _xAxis() {
            return {
                type: 'time',
                position: 'top',
                splitLine: {
                    lineStyle: {
                        color: ['silver'],
                    },
                },
                axisLine: {
                    show: false,
                },
                axisTick: {
                    lineStyle: {
                        color: 'darkgray',
                    },
                },
                axisLabel: {
                    color: 'darkgray',
                    inside: false,
                    align: 'center',
                    formatter: formatDate,
                },
            };
        },

        _yAxis() {
            return {
                axisTick: {show: false},
                splitLine: {show: false},
                axisLine: {show: false},
                axisLabel: {show: false},
                min: 0,
                max: this.absences.employees.data.length,
            };
        },

        absenceChart() {
            if (!this.absences) {
                return {};
            }

            const {employees, absences} = this.absences;

            return {
                tooltip: {
                },
                animation: false,
                dataZoom: this._dataZoom,
                grid: {
                    show: true,
                    top: 40,
                    bottom: 20,
                    left: 100,
                    right: 20,
                    backgroundColor: 'transparent',
                    borderWidth: 0,
                },
                xAxis: this._xAxis,
                yAxis: this._yAxis,
                series: [
                    {
                        id: 'absences',
                        type: 'custom',
                        renderItem: this.renderGanttItem,
                        dimensions: absences.dimensions,
                        encode: {
                            x: [DIM_START_DATE, DIM_END_DATE],
                            y: DIM_INDEX,
                        },
                        data: absences.data,
                        tooltip: {
                            rich: true,
                            formatter({value}) {
                                const startDate = formatDate(value[DIM_START_DATE]);
                                const endDate = formatDate(value[DIM_END_DATE]);

                                return `
                                    <em>${startDate} – ${endDate}</em><br/>
                                `;
                            },
                        },
                    },
                    {
                        id: 'names',
                        type: 'custom',
                        renderItem: this.renderAxisLabelItem,
                        dimensions: employees.dimensions,
                        tooltip: {
                            trigger: 'none',
                        },
                        encode: {
                            x: -1, // Then this series will not be controlled by x.
                            y: DIM_ID,
                        },
                        data: employees.data.map((entry, index) => [index, entry]),
                    },
                ],
            };
        },

        hasChart() {
            return this.absences && this.absences.absences.data.length > 0;
        },
    },

    mounted() {
        this.apiCall(async () => {
            await this.$store.dispatch('dictionaries/fetch');
            await this.fetchData();
        });

        this.$watch('filters', debounce(this.fetchData, 300));
    },

    methods: {
        async fetchData() {
            const absenceFilter = {
                name: this.filters.search,
                startDate: this.filters.startDate,
                endDate: this.filters.endDate,
            };
            this.absences = await getAbsencesChart(absenceFilter);
        },

        getLeaveTypeName(id) {
            const leaveType = this.$store.state.dictionaries.leaveTypes.find((lt) => lt.id === id);
            return leaveType ? leaveType.name : '';
        },

        getBarStyle(text, leaveTypeId, leaveRequestStatus) {
            let fill = '#FF7F27'; // general absence
            let textFill = 'white';
            if (leaveTypeId === 5) { // work from home
                fill = '#99D9EA';
                textFill = 'black';
            } else if (leaveTypeId === 3) { // business trip
                fill = '#B5E61D';
                textFill = 'black';
            }

            return {
                fill,
                text,
                stroke: 'silver',
                textFill,
                fillOpacity: leaveRequestStatus === LeaveRequestStatus.Pending ? 0.5 : 1,
            };
        },

        renderGanttItem(params, api) {
            const idIndex = api.value(DIM_INDEX);
            const dateStartCoords = api.coord([api.value(DIM_START_DATE), idIndex]);
            const dateEndCoords = api.coord([api.value(DIM_END_DATE), idIndex]);

            const width = dateEndCoords[0] - dateStartCoords[0];
            const height = api.size([0, 1])[1];

            const x = dateStartCoords[0];
            const y = dateStartCoords[1] - (height / 2);

            const leaveTypeId = -1;
            const text = this.getLeaveTypeName(leaveTypeId);

            const bar = clipRectByRect(params, {
                x,
                y,
                width,
                height,
            });

            return {
                type: 'group',
                children: [
                    {
                        type: 'rect',
                        shape: bar,
                        style: api.style(
                            this.getBarStyle(
                                text,
                                leaveTypeId,
                                api.value(DIM_LEAVE_STATUS)
                            )
                        ),
                    },
                ],
            };
        },

        renderAxisLabelItem(params, api) {
            const index = api.value(0);
            const employeeName = api.value(DIM_NAME);

            const coords = api.coord([0, index]);
            const y = coords[1];

            return {
                type: 'group',
                position: [
                    -50,
                    y,
                ],
                children: [
                    {
                        type: 'text',
                        style: {
                            x: 100,
                            y: 0,
                            text: employeeName.replace(/,\s*/, '\n'),
                            textVerticalAlign: 'center',
                            textAlign: 'center',
                            textFill: 'darkgray',
                            fillOpacity: 0.6,
                        },
                    },
                ],
            };
        },
    },
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
</script>

<style scoped>
.teamChart {
    position: relative;
}
>>> .echarts {
    width: 100%;
}
</style>
