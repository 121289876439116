import axios, {AxiosResponse} from 'axios';
import {AbstractProvider, Motd} from './AbstractProvider';
import {jsonpAdapter} from '@/shared/axiosJsonpAdapter';

interface QuotesRestResponse {
    quoteText: string;
    quoteAuthor: string;
}

export class QuoteProvider extends AbstractProvider {
    constructor() {
        super('qotd');
    }

    async getMessage(): Promise<Motd> {
        const response: AxiosResponse<QuotesRestResponse> = await axios({
            method: 'get',
            url: 'https://api.forismatic.com/api/1.0/?format=jsonp&method=getQuote&jsonp=callback&lang=en',
            headers: {
                'Content-Type': 'text/plain',
            },
            adapter: jsonpAdapter,
        });

        return {
            text: response.data.quoteText,
            author: response.data.quoteAuthor,
        };
    }
}
