import {Converter} from '@/shared/store/Converter';
import {
    BackendUser, BackendUserUpdate, HermesUser,
} from '@/employees/store/HermesUser';
import {dateOrDefault} from '@/shared/date/dateOrDefault';
import {forServer} from '@/shared/date/convertDate';

export const converter: Converter = {
    dehydrate(item: HermesUser): BackendUserUpdate<Date> {
        const beUser: BackendUserUpdate<Date> = {
            id: item.id,
            userIdGraph: item.userIdGraph,
            isAdmin: item.isAdmin,
            employmentData: {
                bankAccountNumber: item.bankAccountNumber,
                isTerminated: item.isTerminated,
                jiraFabresUsername: item.jiraFabresUsername,
                komplettLogin: item.komplettLogin,
                rambaseLogin: item.rambaseLogin,
                sodexoCardNumber: item.sodexoCardNumber,
                symbolAccountant: item.symbolAccountant,
                timeSheetRequired: item.timeSheetRequired,
                companyVatNumber: item.companyVatNumber,
                companyName: item.companyName,
                companyAddress: item.companyAddress,
                medicalValidityDate: forServer(item.medicalValidityDate),
                country: item.country,
                manager: item.manager,
                employmentType: item.employmentType,
                contractType: item.contractType,
                employeeId: item.employeeId,
                jobTitle: item.jobTitle,
                organizationalUnitName: item.organizationalUnitName,
                workPhone: item.workPhone,
                adAccount: item.adAccount,
                email: item.email,
                location: item.location,
            },
            personalData: {
                celebrateBirthDay: item.celebrateBirthDay,
                citizenship: item.citizenship,
                dateOfBirth: forServer(item.dateOfBirth),
                gender: item.gender,
                pesel: item.pesel,
                placeOfBirth: item.placeOfBirth,
                documentIdNumber: item.documentIdNumber,
                documentTypeId: item.documentTypeId,
                correspondenceAddress: item.correspondenceAddress,
                livingAddress: item.livingAddress,
                registeredAddress: item.registeredAddress,
                workFromHomeAddress: item.workFromHomeAddress,
                workFromHomeAddessFromDate: item.workFromHomeAddessFromDate ? forServer(item.workFromHomeAddessFromDate) : undefined,
                personalCellPhone: item.personalCellPhone,
                personalEmail: item.personalEmail,
                firstName: item.firstName,
                fullName: item.fullName,
                lastName: item.lastName,
                middleName: item.middleName,
                maidenName: item.maidenName,
                motherName: item.motherName,
                fatherName: item.fatherName,
            },
        };

        return beUser;
    },

    hydrate(beUser: BackendUser<string>): HermesUser {
        const {medicalValidityDate, ...employmentData} = beUser.employmentData;
        const {dateOfBirth, workFromHomeAddessFromDate, ...personalData} = beUser.personalData;

        const item: HermesUser = {
            id: beUser.id,
            userIdGraph: beUser.userIdGraph,
            isAdmin: beUser.isAdmin,
            ...employmentData,
            ...personalData,
            dateOfBirth: dateOrDefault(dateOfBirth),
            medicalValidityDate: dateOrDefault(medicalValidityDate),
            workFromHomeAddessFromDate: workFromHomeAddessFromDate ? new Date(workFromHomeAddessFromDate) : undefined,
        };

        return item;
    },
};
