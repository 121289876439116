import {Component, InjectReactive, Vue} from 'vue-property-decorator';
import {MeUser} from '@/employees/store/HermesUser';

@Component({
})
export class InjectViewer extends Vue {
    @InjectReactive()
    viewer!: MeUser;

    get isAdmin(): boolean {
        return this.viewer && this.viewer.isAdmin;
    }

    get isManagerial(): boolean {
        return this.viewer && (this.viewer.isAdmin || this.viewer.isManagingAnyone);
    }
}
