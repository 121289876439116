















import {Component, Mixins, Prop} from 'vue-property-decorator';
import {formatDateForDatePicker} from '@/shared/date/formatDate';
import {Nullable} from '../types/Nullable';
import {InputAttrs} from '@/shared/mixins/InputAttrs';
import {dateOrDefault} from '@/shared/date/dateOrDefault';

interface MinMaxAttrs {
    min?: string;
    max?: string;
}

@Component
export default class DatePicker extends Mixins(InputAttrs) {
    @Prop(Date)
    value!: Date;

    @Prop(String)
    label!: string;

    @Prop(String)
    name!: string;

    @Prop(Boolean)
    disabled!: boolean;

    @Prop({type: Array, default: () => []})
    rules!: Function[];

    // TODO: get rid of nullable
    @Prop({type: Boolean, default: false})
    nullable!: boolean;

    @Prop({type: Date, default: dateOrDefault})
    defaultValue!: Date;

    @Prop({type: Date, default: null})
    min!: Date;

    @Prop({type: Date, default: null})
    max!: Date;

    @Prop({type: Boolean, default: true})
    inputAttrsEnabled!: boolean;

    protected get date(): string {
        return formatDateForDatePicker(this.value);
    }

    protected get minAndMax(): MinMaxAttrs {
        const attrs: MinMaxAttrs = {};
        if (this.min) {
            attrs.min = formatDateForDatePicker(this.min);
        }
        if (this.max) {
            attrs.max = formatDateForDatePicker(this.max);
        }

        return attrs;
    }

    protected get nameAttr(): object {
        if (this.name) {
            return {
                name: this.name,
            };
        } else {
            return {};
        }
    }

    get errorMessages(): string[] {
        return this.rules.map(rule => rule(this.value)).filter(r => r !== true);
    }

    get useInputAttrs(): object {
        if (this.inputAttrsEnabled) {
            return this.inputAttrs;
        }
        return {};
    }

    protected emitDefaultValue(): void {
        this.$emit('input', this.nullable ? null : this.defaultValue);
    }

    protected onChange(value: Nullable<string>): void {
        if (value !== null) {
            this.$emit('input', new Date(value));
        } else {
            this.emitDefaultValue();
        }
    }

    protected onInput(value: Nullable<string>): void {
        if (value !== null) {
            const date = new Date(value);
            if (!isNaN(date.getTime())) {
                this.$emit('input', date);
            }
        } else {
            this.emitDefaultValue();
        }
    }
}
