import {Module} from 'vuex';
import {RootState} from '@/shared/store/RootState';

interface AuthState {
    pending: boolean;
}

export const state: AuthState = {
    pending: true,
};

export const auth: Module<AuthState, RootState> = {
    namespaced: true,
    state,
    mutations: {
        setPending(state, pending): void {
            state.pending = pending;
        },
    },
};
