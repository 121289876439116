export function sortBy<T>(arr: T[], key: keyof T): T[] {
    return arr.sort((a, b): number => {
        if (a[key] > b[key]) {
            return 1;
        } else if (a[key] < b[key]) {
            return -1;
        } else {
            return 0;
        }
    });
}
