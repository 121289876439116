


































import {Component, Mixins} from 'vue-property-decorator';
import * as Msal from '@azure/msal-browser';
import {AuthService} from '@/auth/AuthService';
import {ResponsiveMenuBtn} from '@/shared/mixins/ResponsiveMenuBtn';

@Component({
})
export default class EmployeeMenu extends Mixins(ResponsiveMenuBtn) {
    protected isLoggedIn = false;
    protected user!: Msal.AccountInfo;

    async mounted(): Promise<void> {
        const authService = await AuthService.getInstance();
        this.user = authService.getUser();
        this.isLoggedIn = !!this.user;
    }

    async logOut(): Promise<void> {
        (await AuthService.getInstance()).logout();
    }

    async logIn(): Promise<void> {
        (await AuthService.getInstance()).loginRedirect();
    }
}
