import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import pl from 'vuetify/lib/locale/pl';
import {readSavedLocale} from '@/plugins/i18n';

Vue.use(Vuetify);

export const vuetify = new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#43a1d1',
                anchor: '#43a1d1',
                secondary: '#676666',
                accent: '#ffbc00',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#f0c560',
            },
            dark: {
                primary: '#2b6380',
                anchor: '#43a1d1',
                secondary: '#676666',
                accent: '#ffbc00',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#f0c560',
            },
        },
        dark: !!localStorage.getItem('isDark'),
    },
    icons: {
        iconfont: 'md',
    },
    lang: {
        current: readSavedLocale(),
        locales: {
            pl,
        },
    },
});
