






import {Component, Mixins, Prop} from 'vue-property-decorator';
import ColWidth from '@/shared/mixins/ColWidth';

@Component
export default class HmCol extends Mixins(ColWidth) {
    @Prop(Boolean)
    protected middle!: boolean;

    @Prop(Boolean)
    protected large!: boolean;

    @Prop(Boolean)
    protected wider!: boolean;

    protected colAttrs(): object {
        if (this.large) {
            return this.colWidthFull;
        } else if (this.middle) {
            return this.colWidth2x;
        } else if (this.wider) {
            return this.colWidth15x;
        } else {
            return this.colWidth;
        }
    }
}
