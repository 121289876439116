import {Component, Vue} from 'vue-property-decorator';

@Component({
})
export class IsFullscreen extends Vue {
    get fullscreen(): boolean {
        const breakpoint = this.$vuetify.breakpoint.name;

        return ['xs', 'sm', 'md'].includes(breakpoint);
    }
}
