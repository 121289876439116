import {Day} from '@/timesheets/store/Timesheet';

export function getReportedHoursForDay(day: Day): number {
    if (day.activities.length === 0) {
        return 0;
    }
    return day.activities.reduce<number>((acc, activity) => {
        return acc + activity.hours;
    }, 0);
}
