import {MutationTree} from 'vuex';
import {
    deleteFromList,
    setCurrent,
    setError,
    setIsFetching,
    setList,
    updateCurrent,
    updateList,
    addToList,
} from './commonTypes';
import {AbstractEntity, ID} from './AbstractEntity';
import {EntityState} from './EntityState';
import {Nullable} from '../types/Nullable';

export function setErrorFn<T extends AbstractEntity, TState extends EntityState<T>>(state: TState, error: Nullable<Error>): void {
    state.error = error;
}

export function setIsFetchingFn<T extends AbstractEntity, TState extends EntityState<T>>(state: TState, isFetching: boolean): void {
    state.isFetching = isFetching;
}

export function commonMutations<T extends AbstractEntity>(): MutationTree<EntityState<T>> {
    function updateListFn(state: EntityState<T>, patchedItem: Partial<T>): void {
        if (state.list.length === 0) {
            return;
        }

        const toPatch = state.list.find((item: T): boolean => item.id === patchedItem.id);
        if (toPatch) {
            Object.assign(toPatch, patchedItem);
        }
    }

    return {
        [setError]: setErrorFn,

        [setIsFetching]: setIsFetchingFn,

        [setList](state, list: T[]): void {
            state.list = list;
        },

        [setCurrent](state, current: T): void {
            state.current = current;
        },

        [updateCurrent](state, currentPatch: Partial<T>): void {
            if (!state.current) {
                return;
            }

            Object.assign(state.current, currentPatch);

            updateListFn(state, state.current);
        },

        [updateList]: updateListFn,

        [deleteFromList](state, id: ID): void {
            if (state.list.length === 0) {
                return;
            }

            state.list = state.list.filter((item) => item.id !== id);
        },

        [addToList](state, item): void {
            state.list.push(item);
        },
    };
}
