import {Component, Vue} from 'vue-property-decorator';

@Component
export default class ColWidth extends Vue {
    get colWidth(): object {
        return {
            cols: 12,
            sm: 12,
            md: 6,
            lg: 3,
            xl: 3,
        };
    }

    get colWidth15x(): object {
        return {
            cols: 12,
            sm: 12,
            md: 6,
            lg: 4,
            xl: 4,
        };
    }

    get colWidth2x(): object {
        return {
            cols: 12,
            sm: 12,
            md: 12,
            lg: 6,
            xl: 6,
        };
    }

    get colWidthFull(): object {
        return {
            cols: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
        };
    }
}
