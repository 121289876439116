import {GetterTree} from 'vuex';
import {EmployeeState} from '@/employees/store/EmployeeState';
import {RootState} from '@/shared/store/RootState';
import {like} from '@/shared/like';
import {HermesUser} from '@/employees/store/HermesUser';
import {commonGetters} from '@/shared/store/commonGetters';

export const getters: GetterTree<EmployeeState, RootState> = {
    ...commonGetters,
    filteredEmployees(state, getters, rootState): HermesUser[] {
        let list = state.list;

        if (!state.showTerminated) {
            list = list.filter((employee) => !employee.isTerminated);
        }

        let userSearch = rootState.route.query.userSearch;
        if (userSearch === '' || userSearch === undefined) {
            return list;
        }
        userSearch = userSearch.toLocaleLowerCase();

        const likeTerm = like(userSearch);
        return list.filter((user) => {
            return likeTerm(user.fullName as string)
                || likeTerm(user.jobTitle as string)
                || likeTerm(user.organizationalUnitName as string)
                || likeTerm(user.workPhone as string)
                || likeTerm(user.personalCellPhone as string);
        });
    },
};
