import {Nullable} from '@/shared/types/Nullable';

export function parseHourInput(rawVal: string): Nullable<number> {
    if (rawVal === '') {
        return 0;
    }

    const value = parseFloat(rawVal.replace(/,/, '.'));
    if (isNaN(value)) {
        return null;
    } else {
        return value;
    }
}
