import {Resource} from '@/shared/store/Resource';
import {AxiosPromise} from 'axios';
import {ID} from '@/shared/store/AbstractEntity';
import {HermesUser, MeUser} from '@/employees/store/HermesUser';

export class EmployeeResource extends Resource<HermesUser> {
    public register(graphId: string, parentId?: ID): AxiosPromise<HermesUser> {
        return this.request({
            method: 'post',
            url: this.getUrl(undefined, parentId),
            data: {
                userIdGraph: graphId,
            },
        });
    }

    public readViewer(): AxiosPromise<MeUser> {
        return this.request({
            url: 'me',
            method: 'get',
        });
    }
}
