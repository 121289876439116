import * as Msal from '@azure/msal-browser';
import {loadRuntimeConfig} from '@/shared/loadRuntimeConfig';
import {router} from '@/router';

interface MsalConfig {
    clientId: string;
    graphScopes: string[];
    authority: string;
    volatileAuth: boolean;
}

export class AuthService {
    private applicationConfig: MsalConfig;
    private app: Msal.PublicClientApplication;

    public constructor(config: MsalConfig) {
        this.applicationConfig = config;

        const fullHost = `${location.protocol}//${location.host}`;
        const postLogoutScreen = router.resolve({name: 'postLogout'}).href;

        this.app = new Msal.PublicClientApplication(
            {
                auth: {
                    clientId: config.clientId,
                    authority: `https://login.microsoftonline.com/${config.authority}`,
                    redirectUri: fullHost,
                    postLogoutRedirectUri: `${fullHost}${postLogoutScreen}`,
                    navigateToLoginRequestUrl: true,
                },
                cache: {
                    cacheLocation: config.volatileAuth ? 'sessionStorage' : 'localStorage',
                },
                system: process.env.NODE_ENV === 'development' ? {
                    loggerOptions: {
                        loggerCallback: (level, message, containsPii): void => {
                            if (containsPii) {
                                return;
                            }
                            switch (level) {
                            case Msal.LogLevel.Error:
                                console.error(message);
                                return;
                            case Msal.LogLevel.Info:
                                console.info(message);
                                return;
                            case Msal.LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case Msal.LogLevel.Warning:
                                console.warn(message);
                                return;
                            }
                        },
                    },
                } : undefined,
            }
        );
    }

    public loginRedirect(): void {
        void this.app.loginRedirect({scopes: this.applicationConfig.graphScopes});
    }

    public logout(): void {
        void this.app.logoutRedirect();
    }

    // Graph Related
    public async getGraphToken(): Promise<Msal.AuthenticationResult> {
        const account = this.getUser();
        let accessToken = await this.app.acquireTokenSilent({account, scopes: this.applicationConfig.graphScopes});

        if (!accessToken) {
            accessToken = await this.app.acquireTokenPopup({scopes: this.applicationConfig.graphScopes});
        }

        if (accessToken.expiresOn && accessToken.expiresOn < new Date()) {
            this.loginRedirect();
        }

        return accessToken;
    }

    public getUser(): Msal.AccountInfo {
        return this.app.getActiveAccount() ?? this.app.getAllAccounts()[0];
    }

    public async init() {
        await this.app.initialize();

        try {
            const result = await this.app.handleRedirectPromise();
            if (!result?.account) {
                console.warn('No active account');
                return;
            }

            this.app.setActiveAccount(result.account);
            router.go(0);
        } catch (err) {
            console.error(err);
        }
    }

    private static _instance?: AuthService;

    public static async getInstance(): Promise<AuthService> {
        if (this._instance) {
            return this._instance;
        }

        const config = await loadRuntimeConfig();

        this._instance = new AuthService({
            clientId: config.clientId,
            authority: config.authority,
            volatileAuth: !!config.volatileAuth,
            graphScopes: [
                config.graphScopes,
            ],
        });

        await this._instance.init();

        return this._instance;
    }
}
