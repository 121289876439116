// source: https://github.com/AdonisLau/axios-jsonp

import {AxiosRequestConfig} from 'axios';
import {Nullable} from '@/shared/types/Nullable';

/* eslint-disable @typescript-eslint/ban-ts-ignore */

let cid = 1;

function buildParams(params: any): string { // eslint-disable-line @typescript-eslint/no-explicit-any
    const result = [];

    for (const i of Object.keys(params)) {
        result.push(`${encodeURIComponent(i)}=${encodeURIComponent(params[i])}`);
    }

    return result.join('&');
}

export function jsonpAdapter<T, U>(config: AxiosRequestConfig): Promise<U> {
    return new Promise(function(resolve, reject) {
        let script: Nullable<HTMLScriptElement> = document.createElement('script');
        let src = config.url || '';

        if (config.params) {
            const params = buildParams(config.params);

            if (params) {
                src += (src.indexOf('?') >= 0 ? '&' : '?') + params;
            }
        }

        script.async = true;

        function remove(): void {
            if (script) {
                // @ts-ignore
                script.onload = script.onreadystatechange = script.onerror = null;

                if (script.parentNode) {
                    script.parentNode.removeChild(script);
                }

                script = null;
            }
        }

        const jsonp = `axiosJsonpCallback${cid++}`;
        // @ts-ignore
        const old = window[jsonp];
        let isAbort = false;

        function onResponse(responseData: T): void {
            // @ts-ignore
            window[jsonp] = old;

            if (isAbort) {
                return;
            }

            const response = {
                data: responseData,
                status: 200,
            };

            resolve(response as unknown as U);
        }

        // @ts-ignore
        window[jsonp] = onResponse;

        const additionalParams = {
            _: Date.now(),
        };

        // @ts-ignore
        additionalParams.jsonp = jsonp;

        src += (src.indexOf('?') >= 0 ? '&' : '?') + buildParams(additionalParams);

        // @ts-ignore
        script.onload = script.onreadystatechange = function(): void {
            // @ts-ignore
            if (!script.readyState || /loaded|complete/.test(script.readyState)) {
                remove();
            }
        };

        script.onerror = function(): void {
            remove();

            reject(new Error('Network Error'));
        };

        if (config.cancelToken) {
            config.cancelToken.promise.then(function(cancel) {
                if (!script) {
                    return;
                }

                isAbort = true;

                reject(cancel);
            });
        }

        script.src = src;

        document.head.appendChild(script);
    });
}
/* eslint-enable @typescript-eslint/explicit-function-return-type */
