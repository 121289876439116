import {Component, Vue} from 'vue-property-decorator';

@Component
export class ApiCall extends Vue {
    isFetching = false;
    error = '';

    async apiCall(call: Function, ...args: unknown[]): Promise<void> {
        this.isFetching = true;
        this.error = '';

        try {
            await call(...args);
        } catch (e) {
            if (e.isAxiosError && e.response.status !== 200) {
                // Handle HermesError
                if(e.response?.data?.length>0){
                    const {code, details } = e.response?.data[0];
                    this.error = `${code} ${details ?? ''}`;
                }else{
                    this.error = e.response.data?.errorMessage ?? e.response.data ?? e.message;
                }
            } else {
                this.error = e.message;
            }
        } finally {
            this.isFetching = false;
        }
    }
}
