import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ObjectVModel<TObject> extends Vue {
    @Prop(Object)
    value!: TObject;

    onInput(name: keyof TObject, value: TObject[keyof TObject]): void {
        this.$emit('input', {
            ...this.value,
            [name]: value,
        });
    }
}
